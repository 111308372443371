<template>
  <Data
    class="data-sign"
    :showMenu="false"
    :shadow="false"
    :contractSignature="true"
    :withoutAfterGraphic="true"
  >
    <Signature
      :contract="fakeContract"
      :isAccountSignatureEditor="true"
      :orientation="orientation"
      :signIdentity="fakeSignIdentity"
      @save="saveSignature"
    />
  </Data>
</template>

<script>
import { mapGetters } from 'vuex';
import { setUserSignature } from '@account/services/accountServices';
import Signature from '@signature/views/Signature';

export default {
  name: 'AccountSignature',
  components: {
    Signature,
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
    }),
    fakeContract() {
      return {
        title: this.$t('signing.user_signature'),
        signature_color: this.profile.signature_color,
      };
    },
    fakeSignIdentity() {
      return {
        id: '',
        firstname: this.profile.firstname,
        lastname: this.profile.lastname,
        feature_flags: {
          isSignHeaderIgnored: true,
        },
      };
    },
    orientation() {
      return this.$vuetify.breakpoint.mobile &&
        this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height
        ? 'landscape'
        : 'portrait';
    },
  },
  methods: {
    saveSignature(event) {
      setUserSignature(event)
        .then(() => {
          this.$notification.success(this.$t('contract.completed.signature_saved'));

          if (this.$route.query.fromTutorial) {
            return this.$router.push({
              name: 'home',
              hash: '#profileFinish',
            });
          }

          this.$router.push({
            name: 'account',
            hash: '#signature',
          });
        })
        .catch((err) => {
          if ('User sign image content is empty' === err) {
            this.$notification.error(this.$t('contract.errors.signature_empty'));
            this.loaded = true;

            return;
          }

          this.$notification.error(err?.message || this.$t('general.error'));
        });
    },
  },
};
</script>
