import { apiAuthorizedRequest } from '@/services/ApiService';

/**
 * Method to delete user's avatar image
 *
 * @returns {Promise<*>}
 */
export const deleteAvatar = () => {
  return apiAuthorizedRequest('DELETE', '/api/v1/users/avatar/delete');
};

/**
 * Method to delete saved user's payment card
 *
 * @returns {Promise<*>}
 */
export const deletePaymentCard = () => {
  return apiAuthorizedRequest('DELETE', '/api/v1/users/paymentCard');
};

/**
 * Method to get user's saved signature
 *
 * @returns {Promise<*>}
 */
export const getUserSignature = () => {
  return apiAuthorizedRequest('GET', 'api/v2/loggedUser/signature');
};

/**
 * Method to save user's signature
 *
 * @returns {Promise<*>}
 */
export const setUserSignature = ({ data, progress }) => {
  return apiAuthorizedRequest('POST', 'api/v2/loggedUser/signature', {
    bin_data: data,
    progress: progress,
  });
};

/**
 * Method to delete user's saved signature
 *
 * @returns {Promise<*>}
 */
export const deleteUserSignature = () => {
  return apiAuthorizedRequest('DELETE', 'api/v2/loggedUser/signature');
};
